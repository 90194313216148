.blog-content {
  margin: 0 auto;
  max-width: 600px;
  /* width: 40%; */
}

@media only screen and (max-width: 600px) {
  .blog-content {
    width: 90%;
  }
}
