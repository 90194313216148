@import './colors.scss';

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

/* h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
} */

.text-center {
  text-align: center;
}

.no-style-link {
  color: black;
  text-decoration: none;
}

.underline-on-hover:hover { 
  text-decoration: underline;
}

/* Becomes a light grey on hover */
.text-hover-grey:hover {
  color: $grey;
}

/* Lighter grey than .text-hover-grey that gets applied if text is out of focus */
.text-unfocused-grey {
  color: $lightGrey;
}