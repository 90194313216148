.mouse-on-hover {
  cursor: pointer;
}

.chevron {
  border: solid;
  border-color: #fff;
  border-width: 0 7px 7px 0;
  padding: 9px;
}

.down {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.horizontally-center {
  margin-left: auto;
  margin-right: auto;
}

.display-inline {
  display: inline;
}

.float-right {
  float: right;
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.disabled-link {
  pointer-events: none;
}