.section, .section-grey {
  padding-bottom: 4em;
  padding-top: 2em;
}

.section-grey {
  background-color: #eee;
  /* color:white; */
}

.section-content {
  

  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 760px) {
	.section-content {
    width: 85%;
  }

}

.section-title {
  text-align: center;

  font-size: 3em;
  font-weight: 400;

  margin-top: 0;

  padding-bottom: 0.5em;
  border-bottom: 1px solid #d0d0d0;
}

.section-title-container {
  border-bottom: 1px solid #d0d0d0;
}

.section-title-container h1 {
  text-align: center;

  font-size: 3em;
  font-weight: 400;

  margin-top: 0;
  padding-bottom: 0.5em;
  margin-bottom: 0;

}